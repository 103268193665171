import React, {useState} from 'react'

export default function Textform(props) {
    const handleUpClick = () => {
        console.log("uppercase is clicked" + 'text');
        let newText = text.toUpperCase();
        setText(newText);
    }
    const handleonChange = (event) => {
        console.log("onchange");
        setText(event.target.value);
        
    }
    const handleLpClick = () => {
        console.log("Lowercase is clicked" + 'text');
        let newText = text.toLowerCase();
        setText(newText);
    }
    const handleCpClick = () => {
        console.log("Capitalise is clicked" + 'text');
        let newText = text.split(' ').map(word =>
            word.charAt(0).toUpperCase() + word.slice(1)
          ).join(' ');
        setText(newText);
    }

    const [text, setText] = useState('');
  return (
    <>
    <div className='container'>
        <div className="mb-3">
        <h1 className={`text-${props.colr==='success'?'white':'black'}`}>{props.heading}</h1>
            <textarea className="form-control" onChange={handleonChange}  value={text} id="mytext" rows="8"></textarea>
        </div>
         <button onClick={handleUpClick} className='btn btn-primary'>Convert to uppercase</button>
         <button onClick={handleLpClick} className='btn btn-primary'>Convert to uppercase</button>
         <button onClick={handleCpClick} className='btn btn-primary'>Convert to Capitalise</button>
    </div>
    <div className="container">
        <p>{text.split(" ").filter((element)=>{return element.length != 0}).length} words and {text.length} characters</p>
        <p>{0.008 * text.split(" ").length} minutes</p>
        <h2>Preview</h2>
        <p>{text}</p>
    </div>
    </>
  )
}
