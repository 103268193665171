import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'


export default function Navbar(props) {
  return (
    <nav className={`navbar navbar-expand-lg text-${props.colr==='success'?'blue' :'success'}  bg-${props.mode} bg-${props.colr}`}>
    <div className="container-fluid">
      <Link className={`navbar-brand text-${props.mode==='light'?'dark' :'light'}`} to="/">{props.title}</Link>
      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
          <li className={`nav-item text-${props.mode==='light'?'dark' :'light'}`}>
            <Link className={`nav-link active text-${props.mode==='light'?'dark' :'light'}`} aria-current="page" to="/">Home</Link>
          </li>
          <li className={`nav-item text-${props.mode==='light'?'dark' :'light'}`}>
            <Link className={`nav-link text-${props.mode==='light'?'dark' :'light'}`} to="/AboutUs">{props.navlink}</Link>
          </li>
          
          {/* <li className={`nav-item text-${props.mode==='light'?'dark' :'light'}`}>
            <Link className={`nav-link text-${props.mode==='light'?'dark' :'light'}`} to='/'>Contact us</Link>
          </li> */}
        </ul>
        <form className="d-flex" role="search">
        <div className={`form-check form-switch text-${props.mode==='light'?'dark' :'light'}`}>
            <input className="form-check-input" type="checkbox" id="mode" onClick={props.toggleMode}/>
            <label className="form-check-label"  htmlFor="mode">Enable Dark mode</label>
        </div>
        <div className={`form-check form-switch text-${props.colr==='green'?'blue' :'green'}`}>
            <input className="form-check-input" type="checkbox" id="mode" onClick={props.toogleColr}/>
            <label className="form-check-label"  htmlFor="mode">Enable Green Blue Mode</label>
        </div>

          {/* <input className="form-control me-2" type="search" placeholder="Search" aria-label="Search"/>
          <button className="btn btn-outline-success" type="submit">Search</button> */}
        </form>
      </div>
    </div>
  </nav>
  )
}

Navbar.propTypes={
    title:PropTypes.string,
    navlink:PropTypes.string
}

Navbar.defaultProps={
    title: "RahulSoft",
    navlink:"About"
}