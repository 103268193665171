import logo from './logo.svg';
import './App.css';
import Navbar from './components/Navbar';
import Textform from './components/Textform';
import AboutUs from './components/AboutUS';

import { useState } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";



function App() {
  const [mode, setMode] = useState('light');
  const toggleMode = () =>{ 
  if(mode==="light"){
    setMode('dark')
    
   }
   else{
    setMode('light')
   }
  }
  const [colr, Mycolr] = useState('success');
  const toogleColr = () =>{
    if(colr==="success"){
      Mycolr('blue')
      document.body.style.backgroundColor="blue"
    }
    else{
      Mycolr('success')
      document.body.style.backgroundColor="green"
    }
  }
  return (
    
    <Router>
      <div>
  {/* <Navbar title = "Rahulsoftsol" navlink="About us" mode= {mode} toggleMode ={toggleMode} toggleColr ={toggleColr} /> */}
  <Navbar title = "Rahulsoftsol" navlink="About us" colr= {colr}  toogleColr ={toogleColr} />
   <Routes>
          <Route path="/AboutUs" element={<AboutUs/>} />
          
          <Route path="/" element={<div className='container'>
              <Textform heading="Analyze the text" colr= {colr}  toogleColr ={toogleColr}/>
          </div>}/>
          
    </Routes>

  </div>
  </Router>
  );
}

export default App;
